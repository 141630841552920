// Widescreens
@include mediaXl {
  .projects {
    grid-template-columns: repeat(4, 1fr);
  }
}

// Desktops & Laptops
@include mediaLg {
  .projects {
    grid-template-columns: repeat(3, 1fr);
  }
}

// Tablets & Small Laptops
@include mediaMd {
  main {
    align-items: center;
    text-align: center;

    .lg-heading {
      line-height: 1;
      margin-bottom: 1rem;
    }
  }

  ul.menu-nav,
  div.menu-branding {
    float: none;
    width: 100%;
    min-height: 0;

    &.show {
      transform: translate3d(0, 0, 0);
    }
  }

  .menu-nav {
    height: 75vh;
    transform: translate3d(-100%, 0, 0);
    font-size: 24px;
  }

  .menu-branding {
    height: 25vh;
    transform: translate3d(100%, 0, 0);

    .portrait {
      background: url('../assets/menuPhotoMobile1.jpg');
      width: 150px;
      height: 140px;
    }
  }

  .about-info {
    grid-template-areas: 'bioimage' 'bio' 'job1' 'job2' 'job3';

    grid-template-columns: 1fr;
  }

  .projects {
    grid-template-columns: repeat(2, 1fr);
  }
}

// Smartphones
@include mediaSm {
  main {
    padding: 2rem;

    #home h1 {
      margin-top: 10vh;
    }

    .lg-heading {
      margin-top: 1rem;
      font-size: 5rem;
    }
  }

  .projects {
    grid-template-columns: 1fr;
  }
}
