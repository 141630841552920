@import 'config';
@import 'menu';

* {
  box-sizing: border-box;
}

body {
  @include background;
  background: $primary-color;
  color: set-text-color($primary-color);
  height: 100%;
  margin: 0;
  font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
  line-height: 1.5;
}

// Headings
h1,
h2,
h3 {
  margin: 0;
  font-weight: 400;

  &.lg-heading {
    font-size: 4.5rem;
  }

  &.sm-heading {
    margin-bottom: 2rem;
    padding: 0.2rem 1rem;
    background: rgba(lighten($primary-color, 2), 0.9);
  }
}

a {
  color: set-text-color($primary-color);
  text-decoration: none;
}

header {
  position: fixed;
  z-index: 2;
  width: 100%;
}

.text-secondary {
  color: $secondary-color;
}

main {
  padding: 4rem;
  min-height: calc(100vh - 60px);

  .icons {
    margin-top: 1rem;

    a {
      padding: 0.4rem;

      &:hover {
        color: $secondary-color;
        @include easeOut();
      }
    }
  }

  &#home {
    overflow: hidden;

    h1 {
      margin-top: 20vh;
    }
  }
}

#main {
  overflow: hidden;
}

.about-info {
  display: grid;
  grid-gap: 30px;
  grid-template-areas: 'bioimage bio bio' 'job1 job2 job3';
  grid-template-columns: repeat(3, 1fr);

  .bio-image {
    grid-area: bioimage;
    margin: auto;
    border-radius: 50%;
    border: $secondary-color 3px solid;
  }

  .bio {
    grid-area: bio;
    font-size: 1.5rem;
  }

  .job-1 {
    grid-area: job1;
  }

  .job-2 {
    grid-area: job2;
  }

  .job-3 {
    grid-area: job3;
  }

  .job {
    background: lighten($primary-color, 5);
    padding: 0.5rem;
    border-bottom: $secondary-color 5px solid;

    h2 {
      color: $secondary-color;
    }

    h3 {
      font-style: italic;
    }
  }
}

// Work/Projects
.projects {
  display: grid;
  grid-gap: 0.7rem;
  grid-template-columns: repeat(3, 1fr);

  img {
    width: 100%;
    border: 3px #fff solid;

    &:hover {
      opacity: 0.5;
      border-color: $secondary-color;
      @include easeOut;
    }
  }
}

// Contact Page
.boxes {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-evenly;
  align-items: center;
  margin-top: 1rem;

  div {
    font-size: 1.5rem;
    border: 3px #fff solid;
    padding: 1.5rem 2.5rem;
    margin-bottom: 3rem;
    @include easeOut;

    &:hover {
      padding: 0.5rem 1.5rem;
      background: $secondary-color;
      color: set-text-color($secondary-color);

      span {
        color: set-text-color($secondary-color);
      }
    }
  }
}

// Button Styles
.btn {
  display: block;
  padding: 0.5rem 1rem;
  border: 0;
  margin-bottom: 0.3rem;

  &:hover {
    background: $secondary-color;
    color: set-text-color($secondary-color);
  }
}

.btn-dark {
  @extend .btn;
  background: darken($primary-color, 50);
  color: #fff;
}

.btn-light {
  @extend .btn;
  background: lighten($primary-color, 50);
  color: #333;
}

#main-footer {
  text-align: center;
  padding: 1rem;
  background: darken($primary-color, 10);
  color: set-text-color($primary-color);
  height: 60px;
}

@import 'mobile';
